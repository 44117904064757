import React from 'react'
import { Link, navigate } from 'gatsby'
import PopupBlock from './PopupBlock'

const Footer = ({ language }) => {
  const translations = {
    en: {
      homepageUrl: '/',
      products: 'Products',
      productsUrl: '/products/',
      terms: 'Terms and Conditions',
      termsUrl: '/terms-and-conditions/',
      cart: 'Cart',
      cartUrl: '/cart/',
      materials: 'Materials',
      materialsUrl: '/materials/',
      aboutUs: 'Our Story',
      aboutUsUrl: '/our-story',
      privacy: 'Privacy',
      privacyUrl: '/privacy/',
      returns: 'Returns',
      returnsUrl: '/returns/',
      shipping: 'Shipping',
      shippingUrl: '/shipping/',
      followUs: 'Follow Us',
      aboutUsHeader: 'About Us',
      languageSelect: 'Language',
      press: 'Press',
      pressUrl: '/press',
    },
    nl: {
      homepageUrl: '/nl/',
      products: 'Producten',
      productsUrl: '/nl/producten/',
      terms: 'Algemene Voorwaarden',
      termsUrl: '/nl/algemene-voorwaarden',
      cart: 'Winkelwagen',
      cartUrl: '/nl/winkelwagen/',
      materials: 'Materialen',
      materialsUrl: '/nl/materialen/',
      aboutUs: 'Ons Verhaal',
      aboutUsUrl: '/nl/ons-verhaal',
      privacy: 'Privacy',
      privacyUrl: '/nl/privacy/',
      returns: 'Retour',
      returnsUrl: '/nl/retour/',
      shipping: 'Verzending',
      shippingUrl: '/nl/verzending/',
      followUs: 'Volg Ons',
      aboutUsHeader: 'Over Ons',
      languageSelect: 'Taal',
      press: 'Pers',
      pressUrl: '/nl/pers',
    },
  }

  const lang = translations[language]

  const changeLanguage = event => {
    const pickedLanguage = event.target.value
    if (language === pickedLanguage) {
      return
    } else {
      const targetUrl = translations[pickedLanguage].homepageUrl
      navigate(targetUrl)
    }
  }

  return (
    <>
      <PopupBlock language={language} />
      <footer>
        <div className="footerList">
          <h4>{lang.products}</h4>
          <Link to={lang.productsUrl}>{lang.products}</Link>
          <Link to={`${lang.productsUrl}valta-atmosphere`}>
            Valta Atmosphere
          </Link>
          <Link to={`${lang.productsUrl}valta-aster`}>Valta Aster</Link>
        </div>
        <div className="footerList">
          <h4>{lang.aboutUsHeader}</h4>
          <Link to={lang.aboutUsUrl}>{lang.aboutUs}</Link>
          <Link to={lang.materialsUrl}>{lang.materials}</Link>
          <Link to={lang.shippingUrl}>{lang.shipping}</Link>
          <Link to={lang.returnsUrl}>{lang.returns}</Link>
          <Link to={lang.pressUrl}>{lang.press}</Link>
        </div>
        <div className="footerList">
          <h4>{lang.followUs}</h4>
          <a href="https://instagram.com/valtastudio" rel="noopener nofollow">
            Instagram
          </a>
          <a
            href="https://www.facebook.com/Valta-595716337765066"
            rel="noopener nofollow"
          >
            Facebook
          </a>
          <a href="https://twitter.com/valtastudio" rel="noopener nofollow">
            Twitter
          </a>
        </div>
        <div className="footerList">
          <h4>Contact</h4>
          <p>valtastudio@gmail.com</p>
          <h4 style={{ margin: `20px 0 0 0` }}>{lang.languageSelect}</h4>
          <select
            className="changeLangFooter"
            onChange={event => {
              changeLanguage(event)
            }}
          >
            <option defaultValue value={language === 'en' ? 'en' : 'nl'}>
              {language === 'en' ? 'English' : 'Nederlands'}
            </option>
            <option value={language === 'en' ? 'nl' : 'en'}>
              {language === 'en' ? 'Nederlands' : 'English'}
            </option>
          </select>
        </div>
        <div className="copyrightContainer">
          <p>
            Copyright © 2021{' '}
            <Link to={lang.homepageUrl} style={{ fontWeight: `bold` }}>
              Valta Studio
            </Link>
            .
          </p>
          <div
            style={{
              width: `100%`,
              display: `flex`,
              justifyContent: `center`,
              marginTop: `20px`,
            }}
          >
            <Link to={lang.privacyUrl} style={{ marginRight: `30px` }}>
              {lang.privacy}
            </Link>
            <Link to={lang.termsUrl}> {lang.terms}</Link>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
