import React from 'react'
import { Link } from 'gatsby'

const MobileMenu = ({ toggleMobileMenu, lang }) => {
  return (
    <div id="mobileMenu">
      <nav>
        <Link to={lang.productsUrl} onClick={() => toggleMobileMenu()}>
          {lang.products}
        </Link>
        <Link to={lang.aboutUsUrl} onClick={() => toggleMobileMenu()}>
          {lang.aboutUs}
        </Link>
        <Link to={lang.shippingUrl} onClick={() => toggleMobileMenu()}>
          {lang.shipping}
        </Link>
        <Link to={lang.cartUrl} onClick={() => toggleMobileMenu()}>
          {lang.cart}
        </Link>
        <Link
          to={`${lang.productsUrl}valta-aster`}
          onClick={() => toggleMobileMenu()}
        >
          Valta Aster
        </Link>
        <Link
          to={`${lang.productsUrl}valta-atmosphere`}
          onClick={() => toggleMobileMenu()}
        >
          Valta Atmosphere
        </Link>
      </nav>
    </div>
  )
}

export default MobileMenu
