import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'

const PopupBlock = ({ language }) => {
  const [browserLang, setBrowserLang] = useState()
  const [messageAccepted, setMessageAccepted] = useState(
    (typeof window !== 'undefined' // For server-side rendering
      ? JSON.parse(localStorage.getItem('messageAccepted'))
      : false) || false
  ) // JSON Parse because localstorage only accepts strings, and this value needs to be converted back to Boolean

  useEffect(() => {
    localStorage.setItem('messageAccepted', messageAccepted)
  }, [messageAccepted, setMessageAccepted])

  useEffect(() => {
    const navigatorLang =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage ||
      'en'

    setBrowserLang(navigatorLang)
  }, [])

  return (
    <>
      {browserLang == 'nl' && messageAccepted == false && language != 'nl' && (
        <div className="popupBlockContainer">
          <div
            className="popupBlock"
            onClick={() => {
              setMessageAccepted(true)
            }}
          >
            <Link to="/nl/">
              Verander de taal naar{' '}
              <span style={{ textDecoration: `underline` }}> Nederlands</span>
            </Link>
            <span>
              <svg width="1em" height="1em" viewBox="0 0 14 12" opacity="1">
                <g
                  stroke="#ffffff"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="square"
                  strokeWidth="2"
                >
                  <path d="M12.76.24L1.24 11.76M12.76 11.76L1.24.24"></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
      )}
      {browserLang != 'nl' && messageAccepted == false && language != 'en' && (
        <div className="popupBlockContainer">
          <div
            className="popupBlock"
            onClick={() => {
              setMessageAccepted(true)
            }}
          >
            <Link to="/">
              Change the language to{' '}
              <span style={{ textDecoration: `underline` }}> English</span>
            </Link>
            <span>
              <svg width="1em" height="1em" viewBox="0 0 14 12" opacity="1">
                <g
                  stroke="#ffffff"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="square"
                  strokeWidth="2"
                >
                  <path d="M12.76.24L1.24 11.76M12.76 11.76L1.24.24"></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default PopupBlock
