import React, { useState, useEffect } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Modal = ({ language }) => {
  const [showModal, setShowModal] = useState(
    typeof window !== 'undefined' // For server-side rendering
      ? localStorage.getItem('showEmailSubmit')
        ? JSON.parse(localStorage.getItem('showEmailSubmit'))
        : true
      : true
  ) // JSON Parse because localstorage only accepts strings, and this value needs to be converted back to Boolean

  useEffect(() => {
    localStorage.setItem('showEmailSubmit', showModal)
  }, [showModal, setShowModal])

  // Maybe change to sessionStorage instead of localStorage
  const clickOutside = e => {
    setShowModal(false)
  }

  // Delay Timer
  const [delayPassed, setDelayPassed] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setDelayPassed(true)
    }, 5000)
  }, [])

  // Email things
  const [email, setEmail] = useState('')
  const [resultMsg, setResultMsg] = useState()

  const submitEmail = async e => {
    e.preventDefault()
    const result = await addToMailchimp(email, {
      LANG: language,
    })
    // I recommend setting `result` to React state
    // but you can do whatever you want
    setResultMsg(result)
    setShowModal(false)
  }

  const translations = {
    en: {
      emailTitle: `Join our guest list.`,
      emailSubTitle: `Be the first to hear about new products and offers.`,
      submitButton: `Join`,
    },
    nl: {
      emailTitle: `Kom op onze gastenlijst.`,
      emailSubTitle: `Hoor als eerste van nieuwe producten en aanbiedingen.`,
      submitButton: `Meld aan`,
    },
  }

  const lang = translations[language]

  return (
    <>
      {showModal && delayPassed && (
        <div
          className="modalContainer"
          onClick={e => {
            clickOutside(e)
          }}
        >
          <div
            className="modal"
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <div className="modalBG">
              <button
                onClick={() => {
                  setShowModal(false)
                }}
              >
                <img
                  src={require('../images/close.png')}
                  style={{ width: `15px` }}
                />
              </button>
            </div>
            <div className="modalContent">
              <h2>{lang.emailTitle}</h2>
              <p>{lang.emailSubTitle}</p>

              {/* {children} */}
              <form
                onSubmit={e => {
                  submitEmail(e)
                }}
              >
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                />
                <button type="submit" className="animatedGradient">
                  {lang.submitButton}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Modal
