import React, { useContext, useEffect, useState } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import MobileMenu from './MobileMenu'
import Hreflang from '~/components/Hreflang'

import StoreContext from '~/context/StoreContext'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Header = ({ siteTitle, language, en, nl }) => {
  const translations = {
    en: {
      homepageUrl: '/',
      products: 'Products',
      productsUrl: '/products/',
      terms: 'Terms and Conditions',
      termsUrl: '/terms-and-conditions/',
      cart: 'Cart',
      cartUrl: '/cart/',
      materials: 'Materials',
      materialsUrl: '/materials/',
      aboutUs: 'Our Story',
      aboutUsUrl: '/our-story',
      privacy: 'Privacy',
      privacyUrl: '/privacy/',
      returns: 'Returns',
      returnsUrl: '/returns/',
      shipping: 'Shipping',
      shippingUrl: '/shipping/',
    },
    nl: {
      homepageUrl: '/nl/',
      products: 'Producten',
      productsUrl: '/nl/producten/',
      terms: 'Algemene Voorwaarden',
      termsUrl: '/nl/algemene-voorwaarden',
      cart: 'Winkelwagen',
      cartUrl: '/nl/winkelwagen/',
      materials: 'Materialen',
      materialsUrl: '/nl/materialen/',
      aboutUs: 'Ons Verhaal',
      aboutUsUrl: '/nl/ons-verhaal',
      privacy: 'Privacy',
      privacyUrl: '/nl/privacy/',
      returns: 'Retour',
      returnsUrl: '/nl/retour/',
      shipping: 'Verzending',
      shippingUrl: '/nl/verzending/',
    },
  }

  const lang = translations[language]

  const [hasItems, quantity] = useQuantity()

  const [showMobileMenu, toggleMobileMenu] = useState(false)

  useEffect(() => {
    const bodyEl = document.querySelector('body')
    showMobileMenu
      ? bodyEl.classList.add('lockScroll')
      : bodyEl.classList.remove('lockScroll')
  }, [showMobileMenu])

  return (
    <>
      <Hreflang en={en} nl={nl} />
      <header>
        <div className="menuLinks flexLeft">
          <Link
            className="desktopOnly"
            to={lang.productsUrl}
            style={{ marginRight: `15px` }}
          >
            {lang.products}
          </Link>
          <Link
            className="desktopOnly"
            to={lang.aboutUsUrl}
            style={{ marginRight: `15px` }}
          >
            {lang.aboutUs}
          </Link>
          {/* <Link
            className="desktopOnly"
            to={lang.homepageUrl}
            style={{
              border: `1px solid black`,
              borderRadius: `3px`,
              padding: `3px 6px`,
              textTransform: `uppercase`,
              backgroundColor: `#f4f4f4`,
            }}
          > */}
          {/* {language === 'en' ? 'English' : 'Nederlands'} */}
          {/* {language}
          </Link> */}
          <div
            className="mobileMenu"
            id="mobileMenuButton"
            onClick={() => {
              toggleMobileMenu(!showMobileMenu)
            }}
          >
            menu
          </div>
        </div>
        <Link
          to={lang.homepageUrl}
          className="flexCenter"
          onClick={() => {
            toggleMobileMenu(false)
          }}
        >
          <img src={require(`~/images/valta-logo.png`)} alt={siteTitle} />
        </Link>
        <Link
          to={lang.cartUrl}
          className="headerCart flexRight"
          onClick={() => {
            toggleMobileMenu(false)
          }}
        >
          <div style={{ display: `flex`, alignItems: `center` }}>
            <img src={require(`~/images/cart.png`)} alt="Shopping Cart Icon" />
            {hasItems && <div>{quantity}</div>}
          </div>
        </Link>
      </header>
      {showMobileMenu && (
        <MobileMenu lang={lang} toggleMobileMenu={toggleMobileMenu} />
      )}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
