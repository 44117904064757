import React from 'react'
import Helmet from 'react-helmet'

const Hreflang = ({ en, nl }) => {
  return (
    <Helmet>
      <link
        rel="alternate"
        hreflang="en"
        href={`https://valtastudio.com/${en}`}
      />
      <link
        rel="alternate"
        hreflang="nl"
        href={`https://valtastudio.com/nl/${nl}`}
      />
      <link
        rel="alternate"
        hreflang="x-default"
        href={`https://valtastudio.com/${en}`}
      />
    </Helmet>
  )
}

export default Hreflang
