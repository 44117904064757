import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import ContextProvider from '~/provider/ContextProvider'

import Header from '~/components/Header'
import Footer from '~/components/Footer'
import './layout.css'
import Modal from '~/components/modal'

const Layout = ({ children, language, en, nl }) => {
  return (
    <ContextProvider>
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Header
              siteTitle={data.site.siteMetadata.title}
              language={language}
              en={en}
              nl={nl}
            />
            <Modal language={language}></Modal>
            <div className="gridContainer">{children}</div>
            <Footer language={language} />
          </>
        )}
      />
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
